"use client";

import { useEffect, useState, useTransition } from "react";
import { GameButton } from "./game-button";
import Image from "next/image";
import { GameListReturnType } from "@/lib/declaration";
import _ from "lodash";
import { GetGameList } from "@/lib/actions";
import clsx from "clsx";
import { Spacer } from "@nextui-org/react";
import { useTranslations } from "next-intl";
import { Swiper, SwiperSlide } from "swiper/react";
import { getTransformedCategory } from "@/lib/helper";
import { Pagination } from "@nextui-org/react";
import { debounce } from "lodash";

export default function GamePage({ hasSession, category }: { hasSession: boolean; category: string }) {
  const t = useTranslations();

  const [pending, startTransition] = useTransition();
  const [gameList, setGameList] = useState<GameListReturnType["data"]>([]);
  // const category = useSearchParams().get("category");

  // const [category, setCategory] = useState<string>("slot");
  const [provider, setProvider] = useState<string>("All");
  // const [category, setCategory] = useState<string>("slot");
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<string>("8");
  const [totalCount, setTotalCount] = useState<number>(8);
  const [search, setSearch] = useState<string>();

  const [providerList, setProviderList] = useState<Record<string, string>[]>([]);

  useEffect(() => {
    debounce(() => {
      startTransition(async () => {
        const GameListRes = await GetGameList();

        // console.log(
        //   _.chain(GameListRes.data as GameListReturnType["data"])
        //     .filter((val) => val.ProductCode === "PRAGMATIC_LIVE" || val.ProductCode === "PRAGMATIC_CASINO")
        //     .value()
        // );

        const payload = _.chain(GameListRes.data as GameListReturnType["data"])
          .filter((val) => val.gameId !== "TestGameId")
          .map((val) => {
            // "gameCategoryName": "Pragmatic_Casino",
            return {
              ...val,
              ...(val.ProductCode === "PRAGMATIC_LIVE" && {
                gameCategoryName: "live",
                SubProductCode: "pragmatic",
              }),
              ...(val.ProductCode === "PRAGMATIC_CASINO" && {
                gameCategoryName: "slots",
                SubProductCode: "pragmatic",
              }),
              Icon: val.Icon.replace("med_S.jpg", "med_L.jpg"),
              ...((val.ProductCode === "PRAGMATIC_LIVE" || val.ProductCode === "PRAGMATIC_CASINO") &&
                !val.Icon.startsWith("https://api.prerelease-env.biz/game_pic") && {
                  Icon: ["https://api.prerelease-env.biz/game_pic", val.Icon].join(""),
                }),
            };
          })
          .value();

        const providerList = [
          { val: "All", long: t("all") },
          ..._.chain(payload)
            .filter((val) => val.gameCategoryName === getTransformedCategory(category))
            .map((val) => val.SubProductCode)
            // merge extendednetent with netent
            .map((val) => (val === "extendednetent" ? "netent" : val))
            .uniq()
            .sort()
            .map((val) => {
              switch (val) {
                case "evolution":
                  return { val: "evolution", long: "Evolution" };
                case "pragmatic":
                  return { val: "pragmatic", long: "Pragmatic Casino" };
                case "netent":
                  return { val: "netent", long: "NetEnt" };
                case "redtiger":
                  return { val: "redtiger", long: "Red Tiger" };
                case "btg":
                  return { val: "btg", long: "BTG" };
                case "nlc":
                  return { val: "nlc", long: "NLC" };
                default:
                  return { val, long: val };
              }
            })
            .value(),
        ];

        setProviderList(providerList);

        const filteredPayload = _.chain(payload)
          .filter((val) => val.gameCategoryName === getTransformedCategory(category))
          .filter((val) => {
            if (provider === "All") return true;
            else return val.SubProductCode === provider;
          })
          .filter((val) => {
            if (search && search !== "")
              return val.gameName.replaceAll(" ", "").toLowerCase().includes(search.replaceAll(" ", "").toLowerCase());
            else return true;
          })
          .value();

        setTotalCount(filteredPayload.length);

        const paginatedPayload = _.chain(filteredPayload)
          .slice((page - 1) * Number(count), (page - 1) * Number(count) + Number(count))
          .value();

        setGameList(paginatedPayload);
      });
    }, 500)();
  }, [category, provider, page, count, search]);

  // useEffect(() => {
  //   console.log("count", count);
  //   console.log("totalCount", totalCount);

  //   console.log(Math.ceil(totalCount / Number(count)));
  // }, [count, totalCount]);

  return (
    <>
      <Swiper
        className="h-auto w-full"
        slidesPerView={2.5}
        breakpoints={{
          768: {
            slidesPerView: 6,
          },
        }}
      >
        {_.chain(providerList as { long: string; val: string }[])
          .sortBy((e) => {
            // sort to first if the name starts with All and "pragmatic" to second and the rest sort by alphabet
            if (/^[a|A]ll$/g.test(e.val)) return -1;
            else if (/^(prag)\w+$/g.test(e.val) && category !== "live-dealer") return 0;
            else return 1;
          })
          .map((item, i) => (
            <SwiperSlide className="size-full" key={i}>
              <img
                id={item.val}
                role="button"
                onClick={() => {
                  setProvider(item.val);
                  setPage(1);
                }}
                src={`/assets/img/providers/${item.val.toLowerCase()}.webp`}
                width={500}
                height={500}
                className={clsx(
                  "object-cover object-center transition-transform",
                  provider === item.val ? "scale-110" : "scale-100"
                )}
                alt=""
              />
            </SwiperSlide>
          ))
          .value()}
        {/* {providerList.map((item, i) => (
          <SwiperSlide className="size-full" key={i}>
            <img
              id={item.val}
              role="button"
              onClick={() => {
                setProvider(item.val);
                setPage(1);
              }}
              src={`/assets/img/providers/${item.val.toLowerCase()}.webp`}
              width={500}
              height={500}
              className={clsx(
                "object-cover object-center transition-transform",
                provider === item.val ? "scale-110" : "scale-100"
              )}
              alt=""
            />
          </SwiperSlide>
        ))} */}
      </Swiper>
      <div className="relative mx-auto flex w-full flex-col justify-between gap-4 md:flex-row">
        <Pagination
          className={clsx("mx-auto w-fit md:mx-0", page == undefined && "opacity-0")}
          color="primary"
          onChange={setPage}
          initialPage={1}
          total={Math.ceil(totalCount / Number(count))}
          radius="sm"
          page={page}
        />
      </div>
      <div className="mx-auto grid grid-cols-2 gap-2 md:max-h-none md:grid-cols-4 md:gap-4">
        {gameList.map((game, index) => (
          <GameButton
            key={index}
            hasSession={hasSession}
            gameName={game.gameName}
            image={game.Icon}
            gameId={btoa(game.gameId)}
          />
        ))}
      </div>
      {pending && (
        <div className="fixed left-0 top-0 z-50 !m-0 flex h-svh w-svw flex-col items-center justify-center bg-black/50">
          <div className="xbg-white/10 flex w-full items-center justify-center py-4">
            <Image src={`/assets/img/logo/${process.env.NEXT_PUBLIC_LOGO}`} alt="Logo" height={100} width={100} />
          </div>
          <Spacer y={4} />
          <div className="flex items-center space-x-2">
            <div className="h-3 w-3 animate-bounce rounded-full bg-gray-400" />
            <div className="h-3 w-3 animate-bounce rounded-full bg-gray-400 delay-[500sm]" />
            <div className="h-3 w-3 animate-bounce rounded-full bg-gray-400 delay-[1000sm]" />
          </div>
        </div>
      )}
    </>
  );
}
