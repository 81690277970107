import clsx from "clsx";
import { MouseEventHandler } from "react";
import colors from "tailwindcss/colors";
import { DepositSVG, SwitchSVG, WithdrawSVG } from "../app/[locale]/(profile)/transaction/components/svg";
function DepositAndWithdrawButton(props: JSX.IntrinsicElements["button"]) {
  return (
    <button
      className="size-10 md:size-12 rounded-lg bg-black bg-opacity-90 p-2 transition-transform hover:scale-105 active:scale-95"
      {...props}
    />
  );
}

export function SwitchWalletButton({ switchOnClick }: { switchOnClick?: MouseEventHandler<HTMLButtonElement> }) {
  return (
    <>
      <div className="absolute right-8 top-12 flex items-center justify-center space-x-2">
        <DepositAndWithdrawButton onClick={switchOnClick}>
          <SwitchSVG fill={colors.yellow[200]} height={"100%"} width={"100%"} />
        </DepositAndWithdrawButton>
      </div>
    </>
  );
}

export default function TransactionButtons({
  depositOnClick,
  withdrawOnClick,
  className,
}: {
  depositOnClick?: MouseEventHandler<HTMLButtonElement>;
  withdrawOnClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) {
  return (
    <div className={clsx("absolute right-4 top-12 flex items-center justify-center space-x-2", className)}>
      <DepositAndWithdrawButton onClick={depositOnClick}>
        <DepositSVG fill={colors.green[600]} height={"100%"} width={"100%"} />
      </DepositAndWithdrawButton>
      <DepositAndWithdrawButton onClick={withdrawOnClick}>
        <WithdrawSVG fill={colors.red[600]} height={"100%"} width={"100%"} />
      </DepositAndWithdrawButton>
    </div>
  );
}
