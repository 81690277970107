"use client";
import { Image, Button } from "@nextui-org/react";
import { Link } from "@/i18n/routing";

const GameImage = ({ image, gameName }: { image: string; gameName: string }) => (
  <>
    <Image
      className="aspect-video"
      classNames={{
        wrapper: "size-full bg-center bg-cover xl:aspect-[8/5.5]",
        img: "object-cover object-center size-full",
      }}
      src={`${image}`}
      fallbackSrc={"/assets/img/game-placeholder.webp"}
      alt=""
      width={320}
      height={180}
    />
    <div className="absolute bottom-0 right-0 z-50 flex w-full justify-center rounded-b-2xl bg-black p-3">
      <span className="w-full bg-gradient-to-r from-yellow-200 to-yellow-400 bg-clip-text text-center text-lg font-bold leading-none text-transparent">
        {gameName}
      </span>
    </div>
  </>
);
export const GameButton = ({
  image,
  gameName,
  gameId,
  hasSession,
}: {
  image: string;
  gameName: string;
  gameId: string;
  hasSession?: boolean;
}) => {
  return hasSession ? (
    <a href={`/game/${gameId}`} className="relative size-full transition-all hover:scale-105" target="_blank">
      <GameImage image={image} gameName={gameName} />
    </a>
  ) : (
    <Link href={"/login"} className="relative size-full transition-all hover:scale-105">
      <GameImage image={image} gameName={gameName} />
    </Link>
  );
};
