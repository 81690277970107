"use client";

import { ReactNode, useCallback, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/modal";
import { Button } from "@nextui-org/button";
import { useDisclosure } from "@nextui-org/use-disclosure";
import type { UseDisclosureReturn } from "@nextui-org/use-disclosure";
import Markdown from "react-markdown";

import { getMarkdown as _getMarkdown } from "@/lib/actions";
import { useParams } from "next/navigation";
import remarkGfm from "remark-gfm";
import { useTranslations } from "next-intl";

const ModalObject = ({
  content,
  disclosure: { isOpen, onOpenChange },
}: {
  content?: string;
  disclosure: UseDisclosureReturn;
  }) =>
{
  const t = useTranslations();
  return (
  <Modal
    className="border-1 border-yellow-200 bg-black bg-opacity-75 text-sm text-zinc-200 [&_a]:text-yellow-200 [&_a]:underline [&_h1]:text-2xl [&_h2]:text-lg [&_ol]:list-inside [&_ol]:list-decimal [&_ol]:pl-6 [&_ol_li]:pb-2 [&_strong]:text-white [&_table_td]:border-1 [&_table_td]:p-[5px] [&_th]:border-1 [&_th]:p-[5px] [&_ul]:list-inside [&_ul]:list-disc [&_ul]:pl-6 [&_ul_li]:pb-2"
    isOpen={isOpen}
    size="5xl"
    scrollBehavior="inside"
    onOpenChange={onOpenChange}
    placement="center"
  >
    <ModalContent>
      {(onClose) => (
        <>
          <ModalHeader></ModalHeader>
          {/* <ModalBody dangerouslySetInnerHTML={{ __html: content! }}></ModalBody> */}
          <ModalBody>
            <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
          </ModalBody>
          <ModalFooter>
            <Button radius="full" className="mx-auto w-fit bg-yellow-200 px-12" onClick={onClose}>
            {t("close")}
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  </Modal>
)};

export const Info = () => {
  const [content, setContent] = useState<string>();
  const modalDisclosure = useDisclosure();
  const { locale } = useParams();
  const t = useTranslations();
  const InfoLinks = [
    // { id: "terms1", label: "Contact" },
    // { id: "terms2", label: "About Us" },
    { id: "privacy-policy", label: "privacy_policy" },
    { id: "terms-n-conditions", label: "tnc" },
    { id: "responsible-gambling", label: "responsible_gambling" },
  ];
  const getMarkdown = async (id: string) => {
    // setContent(undefined);
    try {
      const res = await fetch(`/assets/md/${id}/${locale}.md`);
      const text = await res.text();
      // const res = await axios.get(`/api/getMarkdown?id=${id}`);
      // const res = await _getMarkdown(id);
      setContent(text);

      modalDisclosure.onOpen();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="flex w-fit flex-col space-y-5">
        <h4 className="text-[1.25em] text-yellow-300">{process.env.NEXT_PUBLIC_LABEL}</h4>
        <div className="flex flex-col space-y-2 md:space-y-4">
          {InfoLinks.map((link, i) => (
            <button
              onClick={async () => await getMarkdown(link.id)}
              key={i}
              className="text-left text-white hover:text-yellow-300"
            >
              {t(link.label)}
            </button>
          ))}
        </div>
      </div>
      <ModalObject content={content} disclosure={modalDisclosure} />
    </>
  );
};
