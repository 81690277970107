import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/app/[locale]/components/banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/app/[locale]/components/category-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/app/[locale]/components/game-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/app/[locale]/components/server/featured-games/featured-game-swiper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/app/[locale]/components/server/promotion/promotion-swiper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next-intl@3.24.0_next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next-intl@3.24.0_next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/usr/share/nginx/distv2-light/node_modules/.pnpm/next@14.2.20_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4/node_modules/next/dist/client/image-component.js");
