"use client";
import { Link } from "@/i18n/routing";
import Image from "next/image";
import { Info } from "./Info";
import { useTranslations } from "next-intl";

const LogoContainer = ({ label, pathPrefix, length }: { label: string; pathPrefix: string; length: number }) => {
  const t = useTranslations();
  return (
    <div className="space-y-2">
      <h4 className="text-[1.25em] text-yellow-300">{t(label)}</h4>
      <div className="grid grid-cols-4 gap-4 md:grid-cols-5">
        {new Array(length).fill(0).map((_, i) => (
          <Image
            key={i}
            className="aspect-[8/4] h-auto w-full object-contain"
            src={`${pathPrefix}${(i + 1).toString().padStart(2, "0")}.webp?abc`}
            alt=""
            width={500}
            height={500}
          />
        ))}
      </div>
    </div>
  );
};

const SocialLinks = [
  { href: "#", icon: "/assets/img/social/facebook.png" },
  { href: "#", icon: "/assets/img/social/youtube.png" },
  { href: "#", icon: "/assets/img/social/twitter.png" },
];
const SocialNetwork = () => (
  <div className="flex w-fit flex-col space-y-5">
    <h4 className="text-[1.25em] text-yellow-300">Social Network</h4>
    <div className="flex gap-4 invert">
      {SocialLinks.map((link, i) => (
        <Link
          href={link.href}
          key={i}
          className="size-10 bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${link.icon})` }}
        />
      ))}
    </div>
  </div>
);

const Trademark = () => {
  const t = useTranslations();
  return (
    <div className="w-fit space-y-4">
      <Image src={`/assets/img/logo/${process.env.NEXT_PUBLIC_LOGO}`} alt="" width={100} height={80} />
      <div className={"flex items-start gap-2"}>
        <Image src="/assets/img/svg/location.svg" width={20} height={20} alt="location" />
        <div className={"flex flex-col gap-4"}>
          <div className={"uppercase"}>dahan entertainment ltd</div>
          <div>
            49 Victoria Street, Douglas, <br /> Isle of Man, IM1 2LD
          </div>
        </div>
      </div>
      <div className={"flex gap-2"}>
        <Image src="/assets/img/svg/email-.svg" width={20} height={20} alt="location" />
        <a className={"underline"} href={"mailto:support@dahan-e.com"}>
          support@dahan-e.com
        </a>
      </div>
      {/* <div className={"flex gap-2"}>
      <Image src="/assets/img/svg/link.svg" width={20} height={20} alt="location" />
      <a className={"underline"} target={"_blank"} href={"https://dahan-entertainment.com/"}>
        https://dahan-entertainment.com/
      </a>
    </div> */}
      <div className="flex gap-4">
        {/* http://www.gamcare.org.uk/ */}
        <Link href={"https://www.gamcare.org.uk/"} target="_blank">
          <Image src="/assets/img/footer/gam-care.webp" width={126} height={40} alt="" />
        </Link>
        {/* http://www.begambleaware.org/ */}
        <Link href={"https://www.begambleaware.org/"} target="_blank">
          <Image src="/assets/img/footer/gamble-aware.webp" width={126} height={40} alt="" />
        </Link>
      </div>
      <div>{t("trademark")}</div>
      <div className="flex gap-4">
        <Link href={"https://www.gov.im/gambling"} target="_blank">
          <Image src="/assets/img/license-icon1.webp" alt="" width={48} height={48} />
        </Link>
        <Image src="/assets/img/license-icon2.webp" alt="" width={48} height={48} />
      </div>
    </div>
  );
};
export default function Footer() {
  const t = useTranslations();
  return (
    <footer className="mt-auto w-full bg-[#27272A] px-2 pb-0 text-[0.75em] font-light text-white md:px-4 md:pb-6 md:text-[0.85em] [&_h4]:font-semibold [&_h4]:text-[#E3C55C]">
      <div className="mx-auto flex max-w-screen-lg grid-cols-12 grid-rows-6 flex-col space-y-4 pb-12 pt-12 md:grid md:space-y-0 md:pb-0">
        <div className="col-span-4 row-span-full">
          <Trademark />
          <div className="mt-6 hidden md:block">{t("copyright", { label: process.env.NEXT_PUBLIC_LABEL })}</div>
        </div>
        <div className={"col-span-2 row-span-2"}>
          <Info />
        </div>
        <div className={"col-start-8 col-end-13 row-span-2"}>
          <LogoContainer label="partners" pathPrefix="/assets/img/footer/partner/" length={7} />
        </div>
        <div className={"col-start-8 col-end-13 row-span-2"}>
          <LogoContainer label="payment_method" pathPrefix="/assets/img/footer/payment/" length={1} />
        </div>
        <div className="pointer-events-none col-start-5 col-end-7 row-start-3 row-end-5 flex origin-left items-center md:scale-125">
          <Image src="/assets/img/footer/eighteen-plus.webp" alt="" width={50} height={50} />
        </div>
        <div className={"col-start-5 col-end-13 row-span-2 space-y-4"}>
          <div>{t("footer_1", { label: process.env.NEXT_PUBLIC_LABEL })}</div>
          <div>{t("footer_2")}</div>
          <div>{t("footer_3")}</div>
          <div>
            {t("footer_4.a")}{" "}
            <Link
              className="underline"
              href={"https://www.isleofmangsc.com/gambling/online-gambling-licence-holders/"}
              target="_blank"
            >
              {t("footer_4.b")}
            </Link>
          </div>
        </div>
        <div className="mx-auto !mt-12 md:hidden">{t("copyright", { label: process.env.NEXT_PUBLIC_LABEL })}</div>
      </div>
    </footer>
  );
}
