"use client";
import {Button} from "@nextui-org/react";

import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import {useMenu} from "@/components/useMenu";
import clsx from "clsx";

export default function MenuButton({className}: { className?: string }) {
    const {toggleMenu} = useMenu();
    return (
        <Button
            // whileTap={{ scale: 0.95 }}
            onClick={() => toggleMenu()}
            className={clsx("aspect-square h-auto w-8 min-w-8 rounded-md bg-transparent p-0", className)}
        >
            <MenuSharpIcon sx={{width: "100%"}}/>
        </Button>
    );
}
