"use client";

import { Button as _button, ButtonProps } from "@nextui-org/react";
import { useFormStatus } from "react-dom";

export const Button = (props: ButtonProps & { checkTerm?: boolean }) => {
  const { pending } = useFormStatus();
  return (
    <_button
      disabled={props.checkTerm !== undefined ? (props.checkTerm ? pending : !props.checkTerm) : pending}
      className="mt-6 bg-black font-bold text-white disabled:!cursor-not-allowed disabled:!opacity-75 md:mb-0 md:mt-6"
      {...props}
    />
  );
};
