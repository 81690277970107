import colors from "tailwindcss/colors";

export default function ArrowRightSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 4.2333331 4.2333331" {...props}>
      <g>
        <path
          id="path1"
          d="m 2.1795392,-0.39686179 0.6507212,1.1270821 0.6507211,1.12708219 -1.3014423,-1e-7 -1.30144234,0 L 1.528818,0.73022032 Z"
          transform="matrix(0,1.6264006,-1.6514872,0,3.3226161,-1.4281373)"
          fill={colors.black}
        />
      </g>
    </svg>
  );
}