"use client";

import { locales } from "@/i18n/locales";
import { usePathname, useRouter } from "@/i18n/routing";
import { Button, Modal, ModalBody, ModalContent, ModalHeader, ModalFooter } from "@nextui-org/react";
import { UseDisclosureReturn } from "@nextui-org/use-disclosure";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { useParams, useSearchParams } from "next/navigation";
import { useTransition } from "react";
export default function LanguageModal({ disclosure }: { disclosure: UseDisclosureReturn }) {
  const t = useTranslations();
  const [isPending, startTransition] = useTransition();
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const query = useSearchParams();
  const changeLanguage = (lang: string) => {
    startTransition(() => {
      router.replace(
        {
          pathname,
          // @ts-expect-error -- TypeScript will validate that only known `params`
          // are used in combination with a given `pathname`. Since the two will
          // always match for the current route, we can skip runtime checks.
          params,
          query: Object.fromEntries(query.entries()),
        },
        { locale: lang }
      );
    });
  };

  return (
    <Modal
      size="sm"
      placement="center"
      // className="dark"
      onOpenChange={disclosure.onOpenChange}
      isOpen={disclosure.isOpen}
    >
      <ModalContent>
        <ModalHeader>{t("switch_language")}</ModalHeader>
        <ModalBody>
          {locales.map((lang) => (
            <Button
              key={lang}
              id={lang}
              isDisabled={isPending}
              className={clsx({ "border-1 border-yellow-200 bg-yellow-200 font-bold": params.locale === lang })}
              onPress={(e) => changeLanguage(e.target.id)}
            >
              {t(`language.${lang}`)}
            </Button>
          ))}
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}
