"use client";

import { Button, useDisclosure } from "@nextui-org/react";

// import LanguageIcon from "@mui/icons-material/Language";
import LanguageModal from "./language-modal";
import { useParams } from "next/navigation";
import clsx from "clsx";
export default function LanguageButton({ children, className }: { children?: React.ReactNode; className?: string }) {
  const languageDisclosure = useDisclosure();
  const { locale } = useParams();
  return (
    <>
      <LanguageModal disclosure={languageDisclosure} />
      <button
        onClick={() => languageDisclosure.onOpen()}
        className={clsx(
          "relative size-8 flex-none overflow-hidden rounded-full bg-transparent drop-shadow filter",
          className
        )}
      >
        <span
          className={`fi text-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[2rem] fi-${locale === "en" ? "gb" : locale}`}
        />
        {children}
        {/* <LanguageIcon sx={{ width: "100%" }} /> */}
      </button>
    </>
  );
}
