export const TransformBalance = (balance: string, currency: string) => {
  return (
    balance
      // ?.toLocaleString()
      .split(".")
      .map((v, i) => {
        return i === 0 ? v : v.padEnd(2, "0");
      })
      .join(".") +
    " " +
    currency
  );
};

export const getTransformedCategory = (category: string) => {
  switch (category) {
    case "slot":
      return "slots";
    case "live-dealer":
      return "live";
    default:
      return category;
  }
};