"use client";
import {Button} from "@nextui-org/react";
import {Link} from "@/i18n/routing";
import {useTranslations} from "next-intl";
import clsx from "clsx";

export default function AuthButtonGroup({className}: { className?: string }) {
    const t = useTranslations();
    return (
        <>
            <Button as={Link} href="/login"
                    className={clsx("w-full max-w-[120px] bg-[#F5D66B] font-bold text-black", className)}>
                {t("login")}
            </Button>
            <Button as={Link} href="/register"
                    className={clsx("w-full max-w-[120px] bg-[#D0AD22] font-bold text-black", className)}>
                {t("register")}
            </Button>
        </>
    );
}
