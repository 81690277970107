"use client";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import { useEffect, useState } from "react";

import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import ArrowRightSvg from "@/components/svg/ArrowRightSvg";
import clsx from "clsx";
import { useParams } from "next/navigation";
import { useDisclosure } from "@nextui-org/use-disclosure";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/modal";
import type { UseDisclosureReturn } from "@nextui-org/use-disclosure";
import { useTranslations } from "next-intl";
import Markdown from "react-markdown";
import { getMarkdown as _getMarkdown } from "@/lib/actions";
import remarkGfm from "remark-gfm";
import { Button } from "@nextui-org/button";

const ModalObject = ({
  content,
  disclosure: { isOpen, onOpenChange },
}: {
  content?: string;
  disclosure: UseDisclosureReturn;
}) => {
  const t = useTranslations();
  return (
    <Modal
      className="border-1 border-yellow-200 bg-black bg-opacity-75 text-sm text-zinc-200 [&_a]:text-yellow-200 [&_a]:underline [&_h1]:text-2xl [&_h2]:text-lg [&_ol]:list-inside [&_ol]:list-decimal [&_ol]:pl-6 [&_ol_li]:pb-2 [&_strong]:text-white [&_table_td]:border-1 [&_table_td]:p-[5px] [&_th]:border-1 [&_th]:p-[5px] [&_ul]:list-inside [&_ul]:list-disc [&_ul]:pl-6 [&_ul_li]:pb-2"
      isOpen={isOpen}
      size="5xl"
      scrollBehavior="inside"
      onOpenChange={onOpenChange}
      placement="center"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader></ModalHeader>
            {/* <ModalBody dangerouslySetInnerHTML={{ __html: content! }}></ModalBody> */}
            <ModalBody>
              <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
            </ModalBody>
            <ModalFooter>
              <Button radius="full" className="mx-auto w-fit bg-yellow-200 px-12" onClick={onClose}>
                {t("close")}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default function Widget() {
  const { scrollY } = useScroll();
  const [y, setScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { locale } = useParams();
  const [content, setContent] = useState<string>();
  const modalDisclosure = useDisclosure();

  useEffect(() => {
    setIsMobile(window.innerWidth < 475);
    const handleResize = () => setIsMobile(window.innerWidth < 475);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useMotionValueEvent(scrollY, "change", (latest) => setScrollY(latest));

  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
    window.addEventListener("resize", () => {
      setScreenHeight(window.innerHeight);
    });
  }, []);

  const getMarkdown = async (id: string) => {
    // setContent(undefined);
    try {
      const res = await fetch(`/assets/md/${id}/${locale}.md`);
      const text = await res.text();
      // const res = await axios.get(`/api/getMarkdown?id=${id}`);
      // const res = await _getMarkdown(id);
      setContent(text);

      modalDisclosure.onOpen();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <ModalObject content={content} disclosure={modalDisclosure} />
      <motion.div
        initial={{ y: `calc((${y}px + ${screenHeight / 2}px) - 50%)` }}
        animate={{ y: `calc((${y}px + ${screenHeight / 2}px) - 50%)` }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
        className="pointer-events-none absolute right-0 top-0 z-50 w-full overflow-x-hidden"
      >
        <motion.div
          initial={{ x: isMobile ? (mobileOpen ? "0%" : "100%") : "0%" }}
          animate={{ x: isMobile ? (mobileOpen ? "0%" : "100%") : "0%" }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
          className="pointer-events-auto relative ml-auto mr-0 h-fit w-24 rounded-bl-xl bg-[#FFF1D1] py-4 xs:rounded-l-xl"
        >
          <div
            className="absolute left-0 top-0 flex h-24 w-8 -translate-x-[calc(100%-2px)] cursor-pointer items-center justify-center rounded-l-lg bg-[#FFF1D1] xs:hidden"
            onClick={() => {
              setMobileOpen((prev) => !prev);
            }}
          >
            <div className={clsx("size-fit transition-transform duration-700", mobileOpen ? "rotate-0" : "rotate-180")}>
              <ArrowRightSvg />
            </div>
          </div>
          <div className="xgrid relative flex size-full grid-rows-3 flex-col items-center justify-center text-center">
            {/* <div className="grid size-full grid-rows-2 items-center justify-center justify-items-center">
          <CloudDownloadIcon className="aspect-square h-full w-auto rounded-full bg-[#E3C55C] p-2" />
          <div className="w-full min-w-full text-[0.8em] leading-none">
            Download <br /> VVbet Now
          </div>
        </div>
        <div className="grid size-full grid-rows-2 items-center justify-center justify-items-center">
          <LanguageIcon className="aspect-square h-full w-auto rounded-full bg-[#E3C55C] p-2" />
          <div className="w-full min-w-full text-[0.8em] leading-none">
            VVBet <br /> Agent
          </div>
        </div> */}
            <button
              // href="https://join.skype.com/invite/v7USOfCfSiiv"
              // target="_blank"
              onClick={async () => await getMarkdown("faq")}
              className="grid size-full grid-rows-2 items-center justify-center justify-items-center"
            >
              {/* <HeadsetMicIcon
                sx={{
                  height: 48,
                  width: 48,
                }}
                className="aspect-square h-full w-auto rounded-full bg-[#E3C55C] p-2"
              /> */}
              <div className="aspect-square h-full w-auto rounded-full bg-[#E3C55C] p-2">
                <span className="icon-icon-faq text-[24px]" />
              </div>{" "}
              <div className="w-full min-w-full text-[0.8em] leading-none">
                {/* 24/7 <br /> Support */}
                FAQ
              </div>
            </button>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
}
