"use client";
import { UseDisclosureReturn } from "@nextui-org/use-disclosure";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  RadioGroup,
} from "@nextui-org/react";
import clsx from "clsx";
import BankAccountRadio from "../app/[locale]/(profile)/transaction/components/Wallets/BankAccountRadio";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getResponsibleGamingLimit } from "@/lib/actions";
import { LimitType } from "@/lib/constant";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { z } from "zod";
import { useTranslations } from "next-intl";

type TransactionModalProps = {
  disclosure: UseDisclosureReturn;
  currency: string;
  transactionType: "deposit" | "withdrawal";
  currentBalance?: string;
  currentBonuses?: string[];
  onTransactionExecute?: (payload?: Record<string, string | number>) => void;
};

type LimitProps = {
  responsibleGamingLimitID: number;
  partyID: number;
  limitType: string;
  periodType: string;
  effectiveDate: string;
  expiryDate: string;
  limit: number;
  limitUnit: any;
  cumulatedValue: number;
  lastCumulationDateTime: string;
  creationDate: string;
  lastUpdateDate: string;
  status: "PENDING" | "ACTIVE";
};

export const TransactionModal = ({
  disclosure,
  currency,
  transactionType,
  currentBalance,
  currentBonuses,
  onTransactionExecute,
}: TransactionModalProps) => {
  const currentBalanceAmount = parseFloat(currentBalance?.replaceAll(",", "") ?? "0.00");
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [limit, setLimit] = useState<number | undefined>(undefined);
  const t = useTranslations();

  const depositMinimum = useMemo(() => GetDepositMinimumByCurrency(currency as "MYR" | "THB" | "VND" | "IDR"), [currency]);
  // const depositMaximum = useMemo(() => GetDepositMaximumByCurrency(currency as "MYR" | "THB" | "VND"), [currency]);
  const withdrawalMinimum = useMemo(
    () => GetWithdrawalMinimumByCurrency(currency as "MYR" | "THB" | "VND" | "IDR"),
    [currency]
  );
  // const withdrawalMaximum = useMemo(
  //   () => GetWithdrawalMaximumByCurrency(currency as "MYR" | "THB" | "VND"),
  //   [currency]
  // );

  const formRef = useRef<HTMLFormElement>(null);

  const [errorMessages, setErrorMessages] = useState<Record<string, string | undefined>>({});

  const [schema, setSchema] = useState(z.object({}));

  // function GetWithdrawalMaximumByCurrency(currency: "MYR" | "THB" | "VND")
  // { 
  //   switch (currency) {
  //     case "MYR":
  //       return 10000;
  //     case "THB":
  //       return 100000;
  //     case "VND":
  //       return 10000000;
  //     default:
  //       return 1;
  //   }
  // }

  function GetWithdrawalMinimumByCurrency(currency: "MYR" | "THB" | "VND" | "IDR") {
    switch (currency) {
      case "MYR":
        return 50;
      case "THB":
        return 300;
      case "VND":
        return 50000;
      case "IDR":
        return 32000;
      default:
        return 1;
    }
  }

  // function GetDepositMaximumByCurrency(currency: "MYR" | "THB" | "VND")
  // { 
  //   switch (currency) {
  //     case "MYR":
  //       return 10000;
  //     case "THB":
  //       return 100000;
  //     case "VND":
  //       return 10000000;
  //     default:
  //       return 1;
  //   }
  // }

  function GetDepositMinimumByCurrency(currency: "MYR" | "THB" | "VND" | "IDR") {
    switch (currency) {
      case "MYR":
        return 50;
      case "THB":
        return 300;
      case "VND":
        return 50000;
      case "IDR":
        return 32000;
      default:
        return 1;
    }
  }

  useEffect(() => {
    if (limit) {
      if (Number.isNaN(amount)) {
        setErrorMessages((prev) => ({ ...prev, amount: t("error.enter_amount") }));
      } else if (transactionType === "withdrawal" && amount > currentBalanceAmount) {
        setErrorMessages((prev) => ({ ...prev, amount: t("error.low_balance") }));
      } else if (transactionType === "withdrawal" && amount < withdrawalMinimum) {
        setErrorMessages((prev) => ({
          ...prev,
          amount: t("error.min_withdrawal", { amount: withdrawalMinimum.toLocaleString(), currency }),
        }));
      } else if (transactionType === "deposit" && amount < depositMinimum) {
        setErrorMessages((prev) => ({
          ...prev,
          amount: t("error.min_deposit", { amount: depositMinimum.toLocaleString(), currency }),
        }));
      } else if (transactionType === "deposit" && amount > limit) {
        setErrorMessages((prev) => ({
          ...prev,
          amount: t("error.max_deposit", { amount: limit.toLocaleString(), currency }),
        }));
      } else {
        setErrorMessages((prev) => ({}));
      }
    }
  }, [amount, currentBalance, limit, depositMinimum]);

  useEffect(() => {
    if (disclosure.isOpen) {
      setLoading(true);
      setAmount(0);
      getResponsibleGamingLimit([LimitType.DEPOSIT]).then((res) => {
        const { limits } = res as { status: string; limits: LimitProps[] };
        setLimit(limits.find((limit) => limit.limitType === LimitType.DEPOSIT && limit.status === "ACTIVE")?.limit);
        setLoading(false);
      });
      setErrorMessages({});
      setSchema(() =>
        transactionType === "deposit"
          ? z.object({
              amount: z.string().refine((value) => parseFloat(value) >= depositMinimum, {
                message: t("error.min_deposit", { amount: depositMinimum.toLocaleString(), currency }),
              }),
              "deposit-method": z.string({ message: t("error.deposit_method") }).refine((value) => value !== "", {
                message: t("error.deposit_method"),
              }),
            })
          : z.object({
              amount: z.string().refine((value) => parseFloat(value) <= currentBalanceAmount, {
                message: t("error.low_balance"),
              }),
            })
      );
    }
  }, [disclosure.isOpen]);

  function HandleSubmit() {
    const fromData = new FormData(formRef.current!);
    const object = schema.safeParse(Object.fromEntries(fromData.entries()));

    if (object.success) {
      onTransactionExecute?.(object.data);
    } else {
      const errors = object.error.flatten().fieldErrors as Record<string, string[]>;
      console.log(errors);
      setErrorMessages((prev) => ({
        ...Object.fromEntries(Object.entries(errors).map(([key, value]: [string, string[]]) => [key, value[0]])),
      }));
    }
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onOpenChange={disclosure.onOpenChange}
      placement="center"
      isDismissable={false}
      isKeyboardDismissDisabled
      scrollBehavior="inside"
    >
      <ModalContent className="border border-yellow-200 bg-black bg-opacity-90 text-white">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col items-center justify-center gap-1">
              {(() => {
                switch (transactionType) {
                  case "deposit":
                    return <span>{t("deposit")}</span>;
                  case "withdrawal":
                    return <span>{t("withdraw")}</span>;
                }
              })()}
            </ModalHeader>
            <ModalBody className="relative flex flex-col py-2 text-center text-white [&>*]:w-full [&>*]:text-left">
              <AnimatePresence>
                {loading && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute left-0 top-0 z-50 flex size-full flex-col items-center justify-center bg-black bg-opacity-50 text-center text-white"
                  >
                    <div>{t("loading")}...</div>
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="flex flex-col gap-4">
                <form className="flex flex-col gap-6 text-white" ref={formRef}>
                  {transactionType === "deposit" && (
                    <RadioGroup
                      label={t("selectDepositMethod")}
                      classNames={{ label: clsx("text-sm font-bold text-white") }}
                      orientation="horizontal"
                      name="deposit-method"
                      isInvalid={!!errorMessages["deposit-method"]}
                      errorMessage={errorMessages["deposit-method"]}
                      onValueChange={() => {
                        setErrorMessages((prev) => ({ ...prev, depositMethod: undefined }));
                      }}
                      defaultValue={"bank-card"}
                    >
                      <BankAccountRadio value="bank-card">
                        {/* <BankCard className="size-8 [&>*]:fill-yellow-200" /> */}
                        <div className="size-8">
                          <Image src="/assets/img/footer/payment/01.webp" alt="" height={60} width={60} />
                        </div>
                        <span className="block text-[0.6rem] leading-none text-white">Help2Pay</span>
                      </BankAccountRadio>
                      {/* <BankAccountRadio value="online-banking">
                        <OnlineBanking className="size-8 [&>*]:fill-yellow-200" />
                        <span className="block text-[0.6rem] leading-none text-white">Online Banking</span>
                      </BankAccountRadio> */}
                    </RadioGroup>
                  )}
                  <div className="relative space-y-2">
                    <div className="flex justify-between">
                      <label className="text-sm font-bold">{t("enter_amount")} </label>
                      {limit && (
                        <span className="text-xs text-gray-500">
                          {t("amount_limit", { amount: limit.toLocaleString(), currency })}
                        </span>
                      )}
                    </div>
                    <Input
                      classNames={{
                        label: "!text-white font-bold",
                      }}
                      // label={`Enter Amount (${currency})`}
                      // labelPlacement="outside"
                      placeholder={`0.00`}
                      type="text"
                      radius="sm"
                      // readOnly
                      name="amount"
                      onInput={(e) => {
                        e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                        setAmount(parseFloat(e.currentTarget.value));
                      }}
                      endContent={<span className="text-gray-500">{currency}</span>}
                      isInvalid={!!errorMessages.amount}
                      errorMessage={errorMessages.amount}
                    />
                  </div>
                </form>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={loading || Object.keys(errorMessages).length > 0}
                className="mx-auto size-fit bg-[#D0AD22] p-4 px-12 font-semibold text-black disabled:bg-slate-500"
                radius="full"
                onPress={() => {
                  // onClose();
                  HandleSubmit();
                }}
              >
                {(() => {
                  switch (transactionType) {
                    case "deposit":
                      return <span>{t("deposit")}</span>;
                    case "withdrawal":
                      return <span>{t("withdraw")}</span>;
                  }
                })()}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
