"use client";
import dynamic from "next/dynamic";
// import { PaymentPortalModal } from "@/components/PaymentPortal";
import { TransactionModal } from "@/components/TransactionModal";
import TransactionButtons from "@/components/TransactionsButton";
import { TransformBalance } from "@/lib/helper";
import { useDisclosure } from "@nextui-org/use-disclosure";
import { useEffect, useState, useTransition } from "react";
import { getBalanceSimple, LogoutAction } from "@/lib/actions";
import { Skeleton } from "@nextui-org/react";
import { Button } from "@/components/custom";
import { useTranslations } from "next-intl";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";
import { motion } from "framer-motion";

const PaymentPortalModal = dynamic(() => import("@/components/PaymentPortal").then((mod) => mod.PaymentPortalModal), {
  ssr: false,
});

export default function HeaderProfile({
  username,
  isMobile,
  // currency,
}: {
  username?: string;
  isMobile?: boolean;
  // balance?: number;
  // currency?: string;
}) {
  const disclosure = useDisclosure();
  const paymentPortalDisclosure = useDisclosure();
  const [transactionType, setTransactionType] = useState<"deposit" | "withdrawal">("deposit");
  const [amount, setAmount] = useState<number>();
  const [pending, startPending] = useTransition();
  const [balance, setBalance] = useState<string>();
  const [currency, setCurrency] = useState<string>();
  const [toggle, setToggle] = useState(false);
  const t = useTranslations();
  useEffect(() => {
    startPending(async () => {
      try {
        const res = await getBalanceSimple();
        if (res.status === "SUCCESS") {
          console.log(res);
          setBalance(res.withdrawableBalance);
          setCurrency(res.currency);
        }
      } catch (error) {
        console.error("HeaderProfile | Error on getBalanceSimple: ");
        console.dir(error);
      }
    });
  }, []);

  const [isLoggingOut, startLogout] = useTransition();

  const HandleLogout = () => {
    startLogout(async () => {
      await LogoutAction();
    });
  };

  return (
    <>
      <TransactionModal
        disclosure={disclosure}
        currency={currency!}
        transactionType={transactionType}
        currentBalance={String(balance!)}
        onTransactionExecute={(e) => {
          setAmount(e?.amount as number);
          disclosure.onClose();
          paymentPortalDisclosure.onOpen();
        }}
      />
      <PaymentPortalModal amount={amount} method={transactionType} disclosure={paymentPortalDisclosure} />
      <div className="flex items-center gap-2">
        <div className="mr-0 flex flex-col gap-0.5 md:mr-8">
          <div className="text-tiny leading-none md:text-base">{username}</div>
          <Skeleton
            isLoaded={!pending && !!balance && !!currency}
            // isLoaded={false}
            classNames={{
              base: "bg-black/95",
            }}
            className="rounded-md dark"
          >
            <div
              className="relative rounded-md bg-black px-2 py-1.5 text-tiny text-white"
              role={"button"}
              onClick={() => setToggle(!toggle)}
            >
              {balance && currency && (
                <>
                  <div className="absolute bottom-0 right-0 hidden aspect-square h-full w-auto translate-x-full items-center justify-center pl-1.5 md:flex">
                    <motion.button
                      whileTap={{ scale: 0.9, opacity: 0.8 }}
                      onClick={() => setToggle(!toggle)}
                      className="flex h-full w-full items-center justify-center"
                    >
                      {toggle ? <IoEyeOff color="black" size={20} /> : <IoEye color="black" size={20} />}
                    </motion.button>
                  </div>
                  {toggle ? (
                    TransformBalance(balance, currency)
                  ) : (
                    <div className="flex items-center justify-center">
                      <span className="block text-tiny md:text-base">****** {currency}</span>
                    </div>
                  )}
                </>
              )}
              {/* {balance && currency && <>200,000,000,000.00 IND</>} */}
            </div>
          </Skeleton>
        </div>
        <TransactionButtons
          className="!static left-0 top-0 space-x-2"
          depositOnClick={() => {
            setTransactionType("deposit");
            disclosure.onOpen();
          }}
          withdrawOnClick={() => {
            setTransactionType("withdrawal");
            disclosure.onOpen();
          }}
        />
        {!isMobile && (
          <form action={HandleLogout}>
            <Button
              isDisabled={isLoggingOut}
              type="submit"
              className="w-full min-w-32 rounded-lg border-1 border-black bg-transparent font-bold text-black"
            >
              <AiOutlineLogout size={18} color="black" />
              {t("logout")}
            </Button>
          </form>
        )}
      </div>
    </>
  );
}
