"use client";

import { Link } from "@/i18n/routing";
import { Tooltip } from "@nextui-org/react";
import { useLocale, useTranslations } from "next-intl";
import { useState } from "react";

export default function CategoryButton({ short }: { short: string }) {
  const t = useTranslations();
  const locale = useLocale();
  const isComingSoon = /^(sport)$/g.test(short);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isComingSoon ? (
        <Tooltip placement="bottom" size="lg" showArrow content={<span>{t("coming_soon")}</span>} isOpen={isOpen} onOpenChange={setIsOpen}>
          <div
            // ?category=${searchParams.category}&search=${searchParams.search}&subProductCode=${searchParams.subProductCode}&page=${searchParams.page}&count=${e.target.value}
            className="relative size-full opacity-75 grayscale-[0.5] filter transition-transform ease-soft-spring hover:scale-105"
            role="button"
            onPointerEnter={() => setIsOpen(true)}
            onPointerLeave={() => setIsOpen(false)}
            onClick={() => setIsOpen((prev) => !prev)}
            onBlur={() => setIsOpen(false)}
          >
            {/* <Image src={`/assets/img/game-category/${short}.webp`} alt="" width={600} height={522} /> */}
            <svg width="100%" height="30%" viewBox="0 0 600 522" className="size-full">
              {/* image  */}
              <image
                href={`/assets/img/game-category/${short}.webp`}
                width="600"
                height="522"
                preserveAspectRatio="xMidYMid slice"
              />
              <text
                x="50%"
                y="85.5%"
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize={locale === "vn" ? 45 : 65}
                fontWeight={800}
              >
                {t(short?.replace("-", "_"))}
              </text>
            </svg>
          </div>
        </Tooltip>
      ) : (
        <Link
          // ?category=${searchParams.category}&search=${searchParams.search}&subProductCode=${searchParams.subProductCode}&page=${searchParams.page}&count=${e.target.value}
          href={`/game/${short}`}
          className="relative size-full transition-transform ease-soft-spring hover:scale-105"
        >
          {/* <Image src={`/assets/img/game-category/${short}.webp`} alt="" width={600} height={522} /> */}
          <svg width="100%" height="30%" viewBox="0 0 600 522" className="size-full">
            {/* image  */}
            <image
              href={`/assets/img/game-category/${short}.webp`}
              width="600"
              height="522"
              preserveAspectRatio="xMidYMid slice"
            />
            <text
              x="50%"
              y="85.5%"
              textAnchor="middle"
              alignmentBaseline="middle"
              fontSize={locale === "vn" ? 45 : 65}
              fontWeight={800}
            >
              {t(short?.replace("-", "_"))}
            </text>
          </svg>
        </Link>
      )}
    </>
  );
}
