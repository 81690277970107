import { CategoryType } from "./declaration";

  export const CategoryList: CategoryType[] = [
    { short: "slot", long: "Slots" },
    { short: "live-dealer", long: "Live Dealer" },
    // { short: "crash", long: "Crash" },
    // { short: "sport", long: "Sport" },
    // { short: "fishing", long: "Fishing" },
    // { short: "tablegame", long: "Table Game" },
  ];

export enum LimitType
{
  DEPOSIT = "DEPOSIT_LIMIT",
  LOSS = "LOSS_LIMIT",
  WAGER = "WAGER_LIMIT",
  SESSION = "SESSION_LIMIT",
}

export enum PeriodType {
  DYNAMIC = "Dynamic",
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
}

export type LimitRecord = {
  limitType: LimitType;
  periodType: PeriodType;
  effectiveDate: string;
  expiryDate: string;
  limit: number;
  cumulatedValue: number;
  lastCumulationDateTime: string;
  creationDate: string;
  lastUpdateDate: string;
  status: "ACTIVE" | "INACTIVE" | "PENDING";
};

export const ProfileLinks: Record<string, string> = {
  "wallet": "/transaction",
  "profile": "/profile",
  "bet_log": "/bet-log",
  "responsible_gambling": "/responsible-gambling",
  // News: "/news",
  // "Bank Account": "/bank-account",
  // VIP: "/vip",
  // "Daily Check In": "/check-in",
  // "My KYC": "/kyc",
};

export const allowedGames = [
  "10,001 Nights MegaWays",
  "10001 Nights",
  "1942: Sky Warrior",
  "24 Hour Grand Prix",
  "4Squad",
  "5 Families",
  "777 Strike",
  "777 Super Strike",
  "Age Of Akkadia",
  "Agent Royale",
  "American Roulette",
  "Ancient Disco",
  "Ancient Script",
  "Ancients Blessing",
  "Apache Way",
  "Apollo Pays",
  "Arcade Bomb",
  "Astronaut",
  "Astros",
  "Athens MegaWays",
  "Atlantis",
  "Aurum Codex",
  "Aztec Spins",
  "Barbarian Fury",
  "Bass Boss",
  "Beef Lightning",
  "Benji Killed in Vegas",
  "Beriched",
  "Betty, Boris and Boo",
  "Big Bang Boom",
  "Big Cat Rescue Megaways",
  "Blazing Clusters",
  "Blobsters Clusterbuster",
  "Blood & Shadow",
  "Blue Diamond",
  "Bombuster",
  "Bonanza Falls",
  "Bonus Bunnies",
  "Book of Shadows",
  "Bounty Hunter xNudge",
  "Bounty Raid",
  "Bounty Raid 2",
  "Buckshot Wilds",
  "Buffalo Hunter",
  "Buffalo Mania MegaWays",
  "Bugsy's Bar",
  "Bulls Run Wild",
  "Bushido Ways xNudge",
  "Cai Shen 168",
  "Cake and Ice Cream",
  "Case Closed",
  "Cash Or Nothing",
  "Cash Ultimate",
  "Cash Volt",
  "Castle of Terror",
  "Chinese Treasures",
  "Christmas Bonanza",
  "Christmas Catch",
  "Christmas Morning",
  "Cirque de la Fortune",
  "Clash of the Beasts",
  "Classic Blackjack",
  "Clover Craze",
  "Crazy Genie",
  "Crystal Mirror",
  "Cyber Attack",
  "Da Vinci's Mystery",
  "Danger High Voltage",
  "Das xBoot",
  "D-Day",
  "Dead Canary",
  "Devil's Crossroad",
  "Devil's Number",
  "Diamond Blitz",
  "Diamond Fruits",
  "Diamond Royale",
  "Dice Dice Dice",
  "Disturbed",
  "Divine Ways",
  "Dj Psycho",
  "Doggy Riches MegaWays",
  "Don't Eat The Candy",
  "Donuts",
  "Dracula Awakening",
  "Dragon Tribe",
  "Dragon's Fire",
  "Dragon's Fire Infinireels",
  "Dragon's Fire MegaWays",
  "Dragon's Luck",
  "Dragon's Luck Deluxe",
  "Dragon's Luck Megaways",
  "Dragon's Mirror",
  "Druid's Magic",
  "Dynamite Riches",
  "Dynamite Riches MegaWays",
  "Eagle Riches",
  "East Coast vs West Coast",
  "Easy Gold",
  "Egypt Megaways",
  "El Paso Gunfight xNudge",
  "Elven Magic",
  "Epic Journey",
  "Esqueleto Mariachi",
  "Evil Goblins xBomb",
  "Fa Fa Babies",
  "Finn and the Candy Spin",
  "Fire In The Hole 2",
  "Fire in the Hole xBomb",
  "Fireworks Megaways",
  "Fishtastic",
  "Five Star",
  "Five Star Power Reels",
  "Flaming Fox",
  "Flodder",
  "Folsom Prison",
  "Forever 7's",
  "Fortune Charm",
  "Fortune Fest",
  "Fortune House",
  "Fortune House Power Reels",
  "Fruit Snap",
  "Fruits",
  "Gaelic Gold",
  "Gem Crush",
  "Gems Gone Wild",
  "Gems Gone Wild Power Reels",
  "Gems Inferno Megaways",
  "Gemtastic",
  "Genie Nights",
  "Get The Gold InfiniReels",
  "Gifts of Fortune",
  "Giga Blast",
  "Gluttony",
  "God of Wealth",
  "Gods Of Troy",
  "Gold Megaways",
  "Golden Catch",
  "Golden Cryptex",
  "Golden Genie and The Walking Wilds",
  "Golden Leprechaun Megaways",
  "Golden Lotus",
  "Golden Offer",
  "Golden Tsar",
  "Gonzita's Quest",
  "Gonzo's Quest Megaways",
  "Good Luck Clusterbuster",
  "Good Old 7's",
  "Hansel & Gretel Candyhouse",
  "Happy Apples",
  "HarleCoin",
  "Harlequin Carnival",
  "Here Kitty Kitty",
  "Hoard of Poseidon",
  "Hot Hot Chilli Pot",
  "Hot Nudge",
  "Hustling",
  "Imperial Palace",
  "Infectious 5 xWays",
  "Jack In A Pot",
  "Jackpot Quest",
  "Jade Charms",
  "Jelly Belly MegaWays",
  "Jester Spins",
  "Jewel Scarabs",
  "Jingle Balls",
  "Jingle Bells",
  "Jingle Bells Bonanza",
  "Jingle Bells Power Reels",
  "Jingle Ways MegaWays",
  "Judgement Day MegaWays",
  "Karen Maneater",
  "King of Slots",
  "King of Slots Touch",
  "Kingmaker Fully Loaded",
  "Kiss My Chainsaw",
  "Knights Of Avalon",
  "Land Of The Free",
  "Laser Fruit",
  "Last Chance Saloon",
  "Legend of Athena",
  "Legendary Excalibur",
  "Legion X",
  "Lights, Camera, Cash!",
  "Lil' Devil",
  "Little Bighorn",
  "London Tube",
  "Lucky Easter",
  "Lucky Fortune Cat",
  "Lucky Fridays",
  "Lucky Halloween",
  "Lucky Oktoberfest",
  "Lucky Valentine",
  "Lucky Wizard",
  "Magic Gate",
  "Magic Lab",
  "Magic Powers MegaWays",
  "Magic Tricks",
  "Majestic Mysteries Power Reels",
  "Manhattan Goes Wild",
  "Masquerade",
  "Max Megaways 2",
  "Mayan Gods",
  "Mayhem",
  "Mega Dragon",
  "Mega Jade",
  "Mega Pyramid",
  "Mega Rise",
  "Midas Gold",
  "Millionaire",
  "Millionaire Rush",
  "Misery Mining",
  "Monsters Unchained",
  "More Turkey",
  "Multiplier Riches",
  "Mystery Reels Deluxe",
  "Mystery Reels Megaways",
  "Mystery Reels Power Reels ",
  "Mystic Staxx",
  "Mystic Wheel",
  "Narcos",
  "Narcos Mexico",
  "Neon Links",
  "NFT MegaWays",
  "Night Roller",
  "Nine to Five",
  "Ninja Ways",
  "Ocean Fortune",
  "Opal Fruits",
  "Outlaw",
  "Over The Moon",
  "Panda Money",
  "Path of Destiny",
  "Pearl Harbor",
  "Phoenix Fire Power Reels",
  "Piggy Riches Megaways",
  "Pinatas And Ponies",
  "Pirate Pays",
  "Pirates' Plenty",
  "Pirates' Plenty Battle for Gold",
  "Pirates' Plenty MegaWays",
  "PixiesvsPirates",
  "Play with the Devil",
  "Poison Eve",
  "Pop",
  "Possessed",
  "Primate King",
  "Punk Rocker",
  "Punk Toilet",
  "Puss'n Boots",
  "Rage",
  "Rainbow Jackpots",
  "Rainbow Jackpots Power Lines",
  "Rainforest Gold",
  "Ra's Legend",
  "Rasputin Megaways",
  "Reactor",
  "Red Diamond",
  "Red Hot BBQ",
  "Reel Heist",
  "Reel Keeper",
  "Reel Keeper Power Reels",
  "Reel King Mega",
  "Regal Beasts",
  "Regal Streak",
  "Remember Gulag",
  "Reptizillions Power Reels",
  "Riddle Of The Sphinx",
  "Rio Stars",
  "Risque MegaWays",
  "Road Kill",
  "Road Rage",
  "Robin Hood's Wild Forest",
  "Rock Bottom",
  "Rock 'n' Lock",
  "Rocket Men",
  "Royal Gems",
  "Royal Mint",
  "Sakura Masks",
  "San Quentin xWays",
  "Sanctuary",
  "Santa Spins",
  "Serial",
  "Shadow Society",
  "Shah Mat",
  "Sheep Gone Wild",
  "Sirens' Riches",
  "Snow Wild and the 7 Features",
  "Space Donkey",
  "Spin Town",
  "Spooky Carnival",
  "Stage 888",
  "Star Clusters",
  "Starstruck",
  "Stolen Treasures",
  "Sugar Monster",
  "Sugarlicious EveryWay",
  "Sumo Spins",
  "Sylvan Spirits",
  "Ten Elements",
  "Tesla Jolt",
  "The Border",
  "The Cage",
  "The Creepy Carnival",
  "The Crypt",
  "The Equalizer",
  "The Greatest Train Robbery",
  "The Race",
  "The Rave",
  "The Wild Hatter",
  "The Wild Kiss",
  "The Wisecracker Lightning",
  "Thor's Lightning",
  "Thor's Vengeance",
  "Three Kingdoms",
  "Three Musketeers",
  "Deadwood",
  "Tiki Fruits Totem Frenzy",
  "Totem Lightning",
  "Totem Lightning Power Reels",
  "Transylvania Night of Blood",
  "Treasure Mine",
  "Treasure Mine Power Reels",
  "Tricks and Treats",
  "Trillionaire",
  "True Grit Redemption ",
  "True Kult",
  "IceIce Yeti",
  "Immortal Fruits",
  "Ugliest Catch",
  "Vault Cracker",
  "Vault Cracker MegaWays",
  "Vault of Anubis",
  "Vegas Rush",
  "Vicky Ventura",
  "Mayan Magic",
  "Viral Spiral",
  "Walk of Shame",
  "Mental",
  "War Of Gods",
  "Warrior Graveyard xNudge",
  "Oktoberfest",
  "Whacked",
  "Owls",
  "What The Fox MegaWays",
  "Wild Cats Multiline",
  "Wild Elements",
  "Wild Fight",
  "Wild Flower",
  "Wild Nords",
  "Wild O'Clock",
  "Wild Portals",
  "Wild Spartans",
  "Wild Tundra",
  "Win Escalator",
  "Wings of Ra",
  "Winter Wonders",
  "Thor Hammer Time",
  "Tomb of Akhenaten",
  "Wixx",
  "Tomb of Nefertiti",
  "Tombstone",
  "Wolfkin",
  "Wonders of Christmas",
  "Tractor Beam",
  "Wrigley's World",
  "xWays Hoarder xSplit",
  "Year-Round Riches Clusterbuster",
  "Yucatan's Mystery",
  "Zaida's Fortune",
  "Zeus Lightning Power Reels",
  "Zillard King",
  "2 Hand Casino Hold'em",
  "Auto French Roulette",
  "Auto Roulette",
  "Auto Speed Roulette",
  "Control Squeeze Baccarat",
  "Crazy Coin Flip",
  "Crazy Pachinko",
  "Crazy Time",
  "Deal Or No Deal",
  "Double Ball Roulette",
  "Extra Chilli Epic Spins",
  "First Person American Roulette",
  "First Person Blackjack",
  "First Person Craps",
  "control Squeeze Baccarat",
  "First Person Dream Catcher",
  "First Person Lightning 6",
  "First Person Lightning Roulette",
  "First Person Mega Ball",
  "First Person Roulette",
  "First Person Video Poker",
  "First Person XXXtreme Lightning Roulette",
  "Funky Time",
  "Gold Vault Roulette",
  "Infinite Blackjack",
  "Instant Roulette",
  "Instant Super Sic Bo",
  "Lightning Blackjack",
  "Monopoly Big Baller",
  "Monopoly Live",
  "No Commission Baccarat",
  "No Commission Speed Baccarat",
  "Power Blackjack",
  "Prosperity Tree Baccarat",
  "Speed Roulette",
  "Teen Patti",
  "Video Poker",
  "XXXtreme Lightning Baccarat",
  "XXXtreme Lightning Roulette",
];