"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "@nextui-org/react";
import gameList from "@/data/game-list.json";
import { GameData } from "@/lib/declaration";
import { useTranslations } from "next-intl";
export default function PromotionSwiper({ promotions }: { showGameCategory?: boolean; promotions: any[] }) {
  const t = useTranslations();
  return (
    <Swiper className="h-auto w-full" slidesPerView={1} spaceBetween={24} breakpoints={{
      640: {
        slidesPerView: 2,
      },
    }}>
      {promotions.map((promotion, i) => {
        return (
          <SwiperSlide
            key={i}
            className="relative !flex aspect-[500/330] size-full flex-col bg-cover justify-end p-4 rounded-xl"
            style={{ backgroundImage: `url(${promotion.image})` }}
          >
            {/* <div className="space-y-4 w-1/2">
              <div className="text-2xl font-black">{promotion.title}</div>
              <div>{promotion.description}</div>
            </div> */}
            <button className="inline-block rounded-md p-2 px-6 w-fit font-black bg-[#E3C55C]">{t("get-rewarded")}</button>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
